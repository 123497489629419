module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-XXXXXX-X","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Developing Entrepreneural Mongolia","short_name":"Developing Entrepreneural Mongolia","start_url":"/","background_color":"#EBEDF2","theme_color":"#096bd8","display":"standalone","icon":"./static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4046cbd063dcdb7fefb85bf333a0fc07"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
